<template>
  <a-modal
    title="详情"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 账号 -->
            <a-form-item label="账号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                disabled
                placeholder="请输入" 
                v-decorator="['account', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 姓名 -->
            <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                disabled
                :maxLength="15"
                placeholder="请输入" 
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 昵称 -->
            <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                disabled
                :maxLength="15"
                placeholder="请输入" 
                v-decorator="['nickName']"
              />
            </a-form-item>
            <!-- 性别 -->
            <a-form-item label="性别" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-radio-group disabled v-decorator="['sex', { rules: [{ required: true, message: '请选择' }] }]">
                <a-radio :value="1">男</a-radio>
                <a-radio :value="2">女</a-radio>
                <a-radio :value="3">未知&nbsp;&nbsp;&nbsp;&nbsp;</a-radio>
              </a-radio-group>
            </a-form-item>
            <!-- 手机号 -->
            <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                disabled
                :maxLength="11"
                placeholder="请输入" 
                v-decorator="['phone', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { sysUserEdit } from '@/api/modular/system/userManage'
import { sysUserDetail } from '@/api/modular/mallLiving/userAdmin'  //详情接口

export default {
  data() {
    return {
      loading: false,
      imageUrl: '',
      picture: '',
      allList:[],
      cos: null,
      pictureLoading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelCol_1: {
        xs: { span: 24 },
        sm: { span: 3},
      },
      wrapperCol_1: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      parentList:[],  //父级列表
      sort:'',
      id:'',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this)
    }
  },

  methods: {
    // 初始化方法
    edit(record) {
      this.visible = true
      this.picture = ''
      this.id = record.id
      //调取详情接口，回显数据
      sysUserDetail({id: this.id}).then((res)=>{
        if(res.success){
            var details = res.data
            this.picture = details.logo
            // 回显数据
            this.form.setFieldsValue({
                account: details.account,
                name: details.name,
                nickName: details.nickName,
                sex: details.sex,
                phone: details.phone
            })
        }
      })
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
            values.logo = this.picture
            delete values.picture
            // 调取编辑接口
            sysUserEdit({id: this.id, ...values}).then((res) => {
              if(res.success){
                this.$message.success("编辑成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
